<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">体系文件数字化的网格化</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP" style="text-indent: 2em">
        目前很多企业仍然使用传统的文件表达方式，现代的管理体系必须要与互联网对接，为此，体系数字化转型就要采取新的表达方式，这种表达方式叫“文件网格格式”，见下表：
      </p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/21.png"
        class="SonImg"
      />
      <br />
      <p class="SmallP">其原理是基于下图：</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/22.png"
        class="SonImg"
      />
      <div class="PList">
        <p style="text-indent: 2em">
          将此原理运用到文件网格格式中，格式中有一列是数字编码，意思是每个将细分的活动都有对应的数字编码，即其控制深入到流程的每一个活动中。
        </p>
        <p style="text-indent: 2em">
          这种格式的操作性比传统的强很多，因为其除了直观外，每一行还包含了流程的基本要素。数字化转型就要将现有的文件套在该格式中，它最大的一个好处是同时帮助企业进行流程优化，实践已经证明，当将现有的文件套到此格式后，就会发现原来的文件存在很多的问题，例如，流程的输入不能对应输出、找不到输出的接口、缺了很多流程要求（工作标准），等等。所以这是检讨文件的最好方法。
        </p>
        <p style="text-indent: 2em">
          这种格式也是为上软件打下基础的，下面是软件的一个界面：
        </p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/23.png"
          class="SonImgW"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>